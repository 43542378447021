<template>
  <ion-page>
    <Header>
    </Header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col v-if="showWelcome" sizeSm="12" sizeMd="7" sizeLg="8" class="ion-padding-start ion-padding-end">
            <HelpText :text="'home_intro'"></HelpText>
            <ion-text v-if="state.layout === 'mobile'"><h3>Tap below to continue:</h3></ion-text>
            <ion-button v-if="state.layout === 'mobile'" @click="dismissWelcome" expand="full" size="large" class="ion-margin-top"><strong>Login or Sign Up</strong></ion-button>
            <ion-text v-if="state.layout === 'desktop'"><h4><em>First time here? Please click the Signup link below the form to the right.</em></h4></ion-text>
          </ion-col>

          <ion-col v-if="showLoginForm" sizeSm="12" sizeMd="5" sizeLg="4" class="ion-padding-start">
            <form @submit.prevent="submitForm"></form>
            <ion-item v-if="mode === 'signup'" lines="none">
              <ion-label position="stacked">Name</ion-label>
              <ion-input id="name" v-model.trim="name"></ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-label position="stacked">Email</ion-label>
              <ion-input id="email" v-model.trim="email"></ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-label position="stacked">Password</ion-label>
              <ion-input type="password" id="password" v-model.trim="password"></ion-input>
            </ion-item>
            <p v-if="!formIsValid" class="ion-margin-start"><em>Please enter a name, valid email and password (must be at least 6 characters long).</em></p>
            <p v-if="state.loginError" class="ion-margin-start"><em>{{state.loginError}}</em></p>

            <ion-button v-if="state.layout === 'mobile'" @click="submitForm" expand="full" size="large" class="ion-margin-top">{{ submitButtonLabel }}</ion-button>
            <ion-button v-if="state.layout === 'desktop'" @click="submitForm" class="ion-margin-start ion-margin-top">{{ submitButtonLabel }}</ion-button>
            <ion-button @click="switchAuthMode" fill="clear" class="ion-margin-top">{{ switchModeButtonLabel }}</ion-button>
            <ion-button @click="switchPasswordReset" fill="clear" class="ion-margin-top">Reset Password</ion-button>
            <ion-progress-bar type="indeterminate" v-show="isLoading"></ion-progress-bar>
          </ion-col>
        </ion-row>
      </ion-grid>


      <ion-toast
          :is-open=isLoading
          message="Authenticating..."
      ></ion-toast>
      <ion-toast
          :is-open=isError
          :message=error
      >
      </ion-toast>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, IonItem, IonLabel, IonContent, IonInput, IonButton, IonProgressBar, IonToast, IonText, IonGrid, IonRow, IonCol} from '@ionic/vue'
import Header from "@/components/global/Header";
import HelpText from "@/components/HelpText";
import store from '../store'
import auth from "@/mixins/auth";
import router from "@/router";

export default {
  name: "Login",
  components: {IonPage, IonItem, IonLabel, IonContent, IonInput, IonButton, IonProgressBar, IonToast, IonText, IonGrid, IonRow, IonCol, Header, HelpText},
  mixins: [auth],
  data() {
    return {
      name: '',
      email: '',
      password: '',
      formIsValid: true,
      mode: 'login',
      isLoading: false,
      isError: false,
      error: null,
      state: store.state,
      welcomeDismissed: false
    }
  },
  async created() {
    const loggedIn = await this.isLoggedIn;
    if (loggedIn) {
      await router.replace("/tabs/app");
    }
  },
  computed: {
    submitButtonLabel() {
      if (this.mode === 'login') {
        return 'Login'
      } else {
        return 'Signup'
      }
    },
    switchModeButtonLabel() {
      if (this.mode === 'login') {
        return 'Signup'
      } else {
        return 'Login'
      }
    },
    showLoginForm() {
      if (this.state.layout === 'desktop') {
        return true;
      } else {
        return this.welcomeDismissed;
      }
    },
    showWelcome() {
      if (this.state.layout === 'desktop') {
        return true;
      } else {
        return !this.welcomeDismissed;
      }

    },
  },
  methods: {
    async submitForm() {
      if ((this.mode === 'signup' && this.name === '') || this.email === '' || !this.email.includes('@') || this.password.length < 6) {
        this.formIsValid = false;
        return;
      }
      this.isLoading = true;

      try {
        this.isError = false;
        if (this.mode === 'login') {
          try {
            await this.login({
              email: this.email,
              password: this.password
            })
          } catch (err) {
            console.log(err);
            throw Error(err);

          }
        } else {
          await this.signup({
            name: this.name,
            email: this.email,
            password: this.password
          })
        }
      } catch (err) {
        this.isError = true;
        this.error = err.message || 'Failed to authenticate, please try later';
        console.log(this.error);
      }
      this.isLoading = false;
    },
    switchAuthMode() {
      if (this.mode === 'login') {
        this.mode = 'signup';
      } else {
        this.mode = 'login';
      }
    },
    switchPasswordReset() {
      router.push('/reset')
    },
    dismissWelcome() {
      this.welcomeDismissed = true;
    }
  }
}
</script>

<style scoped>

</style>
